import React from 'react';
import './AboutUs.css';

// Sample team member images and information
import member1 from '../assets/images/Akash.jpeg'; // Add images in src/assets/images
import member2 from '../assets/images/kunal.jpg';

function AboutUs() {
  return (
    <section className="about-us">
      <h2>About Us</h2>
      <p>
        Welcome to RBSEBooks.in, where we are passionate about bringing you the best selection of books. Our team is dedicated to providing exceptional service and a curated collection to meet your literary needs.
      </p>
      <div className="team">
        <div className="team-member">
          <img src={member1} alt="Akash Meena" />
          <div className="team-info">
            <h3>Akash Meena</h3>
            <a href="#">B.Tech from IIT KGP</a>
            <p>Founder & MD</p>
          </div>
        </div>
        <div className="team-member">
          <img src={member2} alt="Kunal Malav" />
          <div className="team-info">
            <h3>Kunal Malav</h3>
            <a href="#">B.Tech from NIT Patna</a>
            <p>Founder & CEO</p>
          </div>
        </div>
        {/* Add more team members as needed */}
      </div>
    </section>
  );
}

export default AboutUs;
