
import React from 'react';
import './ProductModal.css';

function ProductModal({ product, onClose }) {
  if (!product) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        <img src={product.image} alt={product.name} className="modal-image" />
        <h2>{product.name}</h2>
        <p className="modal-price">{product.price}</p>
        <p className="modal-description">{product.description}</p>
        <button className="purchase-button">Purchase</button>
      </div>
    </div>
  );
}

export default ProductModal;
