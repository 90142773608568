import React, { useState } from 'react';
import ProductModal from './ProductModal';
import './Products.css';

const products = [
  { id: 1, name: ' Mathematics for RBSE - Concept to Perfection', price: 'Rs.999', image: './images/book1.jpg' ,description: 'Strengthen your math foundation with this exhaustive textbook covering algebra, geometry, calculus, and more. With hundreds of practice questions, detailed solutions, and tips for problem-solving, it ensures you’re fully equipped for your exams. Perfect for mastering both the fundamentals and advanced topics of RBSE Mathematics.' },
  { id: 2, name: 'Mastering Physics for RBSE - A Comprehensive Guide', price: 'Rs.999', image: './images/book2.jpg', description: 'Dive deep into the world of physics with clear explanations, step-by-step derivations, and a wide range of solved problems. This book covers everything from mechanics to modern physics, making complex concepts easy to grasp. Whether youre preparing for board exams or competitive tests, this is the ultimate guide for mastering RBSE Physics.' },
  { id: 3, name: 'Exploring Biology for RBSE - Your Gateway to Life Sciences', price: 'Rs.999', image: '/images/book3.jpg', description: 'Understand the fascinating world of living organisms with simplified diagrams, comprehensive theories, and in-depth explanations of human physiology, plants, genetics, and evolution. This book offers a perfect balance between theoretical knowledge and practical applications, tailored specifically for RBSE Biology students.' },
  { id: 4, name: 'RBSE Chemistry - Building Blocks of Matter', price: 'Rs.999', image: '/images/book4.jpg', description: 'Get ready to ace your chemistry exams with a book that simplifies everything from atomic structures to chemical reactions. With detailed explanations, colorful illustrations, and numerous solved examples, this book breaks down difficult topics and makes them easy to understand. Ideal for both quick revision and in-depth learning.' },
  // Add more products as needed
];

function Products() {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleExplore = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  return (
    <section className="products">
      <h2>Our Products</h2>
      <div className="product-list">
        {products.map((product) => (
          <div className="product-card" key={product.id}>
            <img src={product.image} alt={product.name} />
            <div className="product-info">
              <h3>{product.name}</h3>
              <p className="price">{product.price}</p>
              <button onClick={() => handleExplore(product)}>Explore</button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for showing product details */}
      {selectedProduct && (
        <ProductModal product={selectedProduct} onClose={closeModal} />
      )}
    </section>
  );
}

export default Products;
