import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <section className="contact">
      <h2>Contact Us</h2>
      <div className="contact-info">
        <div className="contact-item">
          <i className="fa fa-map-marker"></i>
          <p>Talwandi, Kota, Rajasthan, 324005</p>
        </div>
        <div className="contact-item">
          <i className="fa fa-phone"></i>
          <p>7891636027</p>
        </div>
        <div className="contact-item">
          <i className="fa fa-envelope"></i>
          <p>info@rbsebooks.in</p>
        </div>
      </div>
      <form className="contact-form">
        <input type="text" placeholder="Name" />
        <input type="email" placeholder="Email" />
        <textarea placeholder="Message"></textarea>
        <button type="submit">Send</button>
      </form>
    </section>
  );
}

export default Contact;
