import React from 'react';
import Header from './components/Header';
import Intro from './components/Intro';
import Products from './components/Products';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';

function App() {
  return (
    <div>
      <Header />
      <Intro />
      <Products />
      <AboutUs />
      <Contact />
    </div>
  );
}

export default App;
