import React from 'react';
import './Intro.css';

function Intro() {
  return (
    <section className="intro">
      <h2>Welcome to RBSEBooks.in</h2>
      <p>Unlock Your Academic Potential with the Best RBSE Study Resources!</p>
    </section>
  );
}

export default Intro;
